@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --dark-gray: #161616;
    --dark-gray-2: #1A1A1A;
    --dark-gray-3: #2E2E2E;
    --dark-gray-4: #333333;
    --dark-gray-5: #666666;
    --medium-gray: #666666;
    --medium-gray-2: #9E9E9E;
    --light-gray: #CCCCCC;
}

html, body {
    height: 100%;
    width: 100%;
    
    margin: 0;
    padding: 0;
    
    background-color: var(--dark-gray);
    color: var(--dark-gray-5);
    
    font-family: 'Roboto', sans-serif;
    font-size: 1rem;

    position: relative;
    overflow-x: hidden;
}

body::before {
    content: '';
    background-image: url('../public/assets/images/logo-bg-0.svg');
    background-repeat: no-repeat;
    background-position: 50% 24%;
    background-size: 125rem;

    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;

    
}

.container * {
    z-index: 1;
}

.container::before {
    content: '';
        background-image: url('../public/assets/images/logo-bg-1.svg');
        background-repeat: no-repeat;
        background-position: 0% 30%;
        background-size: 37.5rem;

        position: absolute;
        z-index: 0;
        opacity: 0.5;
        
        width: 100%;
        height: 100vh;
    }
